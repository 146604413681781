
//@import "bootstrap/scss/bootstrap";
@import "@ng-select/ng-select/themes/default.theme.css";


/* You can add global styles to this file, and also import other style files */

.ck-editor__editable_inline {
  min-height: 400px;
}

.medium .ck-editor__editable_inline {
  min-height: 250px;
}

.small .ck-editor__editable_inline {
  min-height: 150px;
}

.table-responsive {
  overflow-x: unset!important;
}

/* Ovveride bootstrap default style */
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent !important;
}

/* Unit of measure color and style for all site */
.measure-unit {
  text-transform: initial;
  margin-left: 5px;
  color: #257ed7;
}

/* hide recaptcha badge*/
.grecaptcha-badge {
  visibility: hidden;
}

/* only show recaptcha badge on needed pages */
body.recaptcha .grecaptcha-badge {
  visibility: visible;
}
